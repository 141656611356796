
import "../assets/styles/Footer.css"

function Footer() {

    return (
        <div>
            <p>Se site à été développé sous <a href="https://react.dev/">React</a> et mis en ligne sur <a href="https://www.hostinger.fr/">Hostinger</a> </p>
        </div>
        
    );
  }
  
  export default Footer;